





























































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import CompanyService from "@/services/CompanyService";
import BusinessTaxReturnDTO from "@/dto/taxation/business/BusinessTaxReturnDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import BusinessTaxReturnFilter from "@/dto/taxation/business/BusinessTaxReturnFilter";
import BusinessTaxReturnModal from "@/components/profile/taxation/BusinessTaxReturnModal.vue";
import UserDTO from "@/dto/UserDTO";
import RouteNames from "@/router/RouteNames";
import DocumentActionModal from "@/components/util/DocumentActionModal.vue";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class BusinessTaxReturn extends Vue {
    private periods: Array<string> = [];
    private filter: BusinessTaxReturnFilter = new BusinessTaxReturnFilter();
    private businessTaxReturns: Array<BusinessTaxReturnDTO> = [];

    @AppModule.State
    private isMobile!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    @Auth.State
    private user!: UserDTO

    private message = "";

    private successful = false;

    mounted() {
      this.loadAllBTRPeriods().then(() => this.loadBusinessTaxReports());
    }

    showActionsModal(dto: BusinessTaxReturnDTO) {
        this.$modal.show(
            DocumentActionModal,
            {
                onDelete: () => this.deleteBusinessTaxReturn(dto.id as number),
                onDownload: () => Vue.prototype.$docUtils.download(dto.businessTaxReturnFileMeta!.id)
            },
            {width: "50%", height: "auto"}
        );
    }

    private deleteBusinessTaxReturn(id: number) {
        this.startLoading()
        CompanyService.deleteBusinessTaxReturnById(id).then(
            () => {
                this.successful = true;
                this.message = "Business taxation report successfully deleted!";
                this.startLoading();
                this.loadBusinessTaxReports();
            },
            err => this.errorHandle(err)
        )
    }

    errorHandle(error: any) {
        this.successful = false;
        const param = error?.response?.data?.param ? { param: error?.response?.data?.param } : {}
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error), param) as string;
        this.stopLoading();
    }

    private loadAllBTRPeriods() {
        this.startLoading();
        this.message = "";
        this.successful = false;
        return  CompanyService.getAllBTRPeriods(Workspaces.getCurrent.id).then(
            res => {
                this.periods = res.data;
                this.filter.period = this.periods[0];
                this.successful = true;
                this.stopLoading();
            },
            err => this.errorHandle(err)
        )
    }

    private loadBusinessTaxReports(period = "") {
        this.startLoading()
        this.message = "";
        if (period) {
            this.filter.period = period;
        }
        this.filter.companyId = Workspaces.getCurrent.id;
        CompanyService.getAllBusinessTaxReturns(this.filter).then(
            res => {
                this.businessTaxReturns = res.data;
                this.successful = true;
                this.stopLoading();
            },
            err => this.errorHandle(err)
        );
    }

    formatLine(name: string, length = 23) {
        return name.length > 20 ? name.substr(0, length) + "..." : name;
    }

    openModal() {
        this.$modal.show(
            BusinessTaxReturnModal,
            {
                onSave: (period : string) => this.loadAllBTRPeriods().then(() => this.loadBusinessTaxReports(period))
            },
            {
                width: "50%",
                height: "auto",
                scrollable: true,
            }
        );
    }
}
